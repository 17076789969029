@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&family=Lato:wght@400;700&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* 1) Body uses Lato by default */
  body {
    
    font-family: "Lato", system-ui, -apple-system, sans-serif;
  }

  /* 2) Headings use IBM Plex Sans by default */
  h1, h2, h3, h4, h5, h6 {
    font-family: "IBM Plex Sans", system-ui, -apple-system, sans-serif;
  }
}

.App {
  text-align: center;
  font-family: Inter, system-ui, -apple-system, sans-serif;
  font-weight: bold;
}



body {
  padding-top: 80px;
}

.NavBar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  font-weight: bold;
  font-family: inherit; /* Ensures it uses the same font family as .App */
}

.bg-white {
  --bs-bg-opacity: 1 !important;
}

a {
  text-decoration: none !important;
  color: darkcyan !important;

}

@media only screen and (max-width: 768px) {
  .form-container {
    width: 80% !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Button {
  background-color: darkcyan;
}

.Button:hover{
  background-color: teal;
}

.step-content {
  text-align: center;
  font-size: 20px; /* Larger text */
}

.form-wrapper {
  overflow-x: hidden;
  overflow-y: auto; /* Allow vertical scrolling if necessary */
  width: 100%;
  height: 600px; /* Adjust based on content */
}

.form-container {
  width: 40%;
  margin: 0 auto;
}

.steps-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.form-step {
  min-width: 100%;  /* Ensures each step takes full width */
  transition: opacity 0.5s ease-in-out;
  justify-content: center; /* Center content of steps */
  align-items: center; /* Center content vertically */
}

.navigation-buttons {
  padding-top: 50px;
  text-align: center;
  width: 100%;
}

.back-button, .continue-button, .submit-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: teal; /* Style according to your preference */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /*width: 100px;*/
}

.back-button:hover, .continue-button:hover, .submit-button:hover {
  background-color: darkcyan;
}

/* Hidden input for clean styling */
.hidden {
  display: none;
}

/* Style for the custom radio button label */
/* Flexbox alignment for the label */
.label {
  display: flex;
  align-items: center; /* Centers the content vertically */
  justify-content: space-between; /* Distributes space between and around content items */
  text-align: left; /* Aligns text to the left */
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.label.peer-checked {
  background-color: teal; /* Teal background when checked */
  border-color: teal; /* Teal border when checked */
  color: white; /* White text when checked */
}

.label:hover {
  background-color: #f8f8f8; /* Light grey background on hover */
  color: #333; /* Normal text color on hover */
}

/* Ensure checkbox labels are styled uniformly */
/* .checkbox-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
} */
.checkbox-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: rgb(252, 252, 252);
  border-radius: 12px;
  border: 2px solid transparent;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  text-align: left;
}

.sticky-heading {
  position: sticky;
  top: 0;
  background-color: white; /* Ensure the background covers the content behind the sticky element */
  z-index: 10; /* Ensure it stays above the form content */
  padding-bottom: 25px;
  /* border-bottom: 1px solid #ccc;  */
}

.checkbox-input {
  display: none;
}

/* .checkbox-input:checked + .checkbox-label {
  background-color: teal; 
  border-color: teal;
  color: white; 
} */

/* .checkbox-label:hover {
  background-color: #f8f8f8; 
  color: #333; 
} */
.checkbox-input:checked + .checkbox-label {
  background-color: rgb(13 148 136); /* Light teal background */
  border-color: rgb(13 148 136); /* Teal border */
  color: white; /* Teal text */
  box-shadow: 0 4px 6px -1px rgba(13, 148, 136, 0.1), 0 2px 4px -1px rgba(13, 148, 136, 0.06);
}

.checkbox-label:hover {
  transform: translateY(-1px);
  box-shadow: 0 6px 8px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.06);
  border-color: rgb(13 148 136 / 0.3);
  background-color: rgb(20 184 166 / 0.05);
}

.ol {
  padding-left: 0;
}

ol, ul {
  padding-left: 0 !important;
}
